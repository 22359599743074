var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"title":"Lease Areas","icon":"mdi-file-document-outline"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('toolbar',{attrs:{"left-items":[
                { id: 'save', preset: 'save', onClick: _vm.saveFormData, size: 'small' },
                { id: 'done', preset: 'done', redirectUrl: _vm.doneRedirect, size: 'small' },
            ]}})]},proxy:true}])},[_c('v-container',{staticClass:"d-flex",staticStyle:{"max-width":"100%"}},[_c('v-col',{attrs:{"cols":"4"}},[(_vm.leaseData)?_c('smart-form',{attrs:{"value":_vm.leaseData,"schema":_vm.formSchema,"hide-border":""}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"8"}},[(_vm.tableValue)?_c('smart-table',{key:JSON.stringify(_vm.tableValue),attrs:{"headers":_vm.headers,"items":_vm.tableValue,"dense":"","items-per-page":1000,"items-per-page-options":[1000],"show-select":"","item-key":"propertyAreaId"},scopedSlots:_vm._u([{key:"item.netLetArea",fn:function({ item }){return [(
                            _vm.selectedAreas.find(
                                (oneSelectedArea) => oneSelectedArea.propertyAreaId === item.propertyAreaId,
                            )
                        )?_c('div',[_c('currency-field',{staticClass:"mt-3",attrs:{"dense":"","currency-symbol":"","precision":0},model:{value:(item.netLetArea),callback:function ($$v) {_vm.$set(item, "netLetArea", $$v)},expression:"item.netLetArea"}})],1):_vm._e()]}}],null,false,108703984),model:{value:(_vm.selectedAreas),callback:function ($$v) {_vm.selectedAreas=$$v},expression:"selectedAreas"}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }