<template>
    <Page title="Lease Areas" icon="mdi-file-document-outline">
        <template v-slot:header>
            <toolbar
                :left-items="[
                    { id: 'save', preset: 'save', onClick: saveFormData, size: 'small' },
                    { id: 'done', preset: 'done', redirectUrl: doneRedirect, size: 'small' },
                ]"
            />
        </template>
        <v-container class="d-flex" style="max-width: 100%">
            <v-col cols="4">
                <smart-form v-if="leaseData" :value="leaseData" :schema="formSchema" hide-border />
            </v-col>
            <v-col cols="8">
                <smart-table
                    v-if="tableValue"
                    :headers="headers"
                    :items="tableValue"
                    dense
                    :items-per-page="1000"
                    :items-per-page-options="[1000]"
                    show-select
                    item-key="propertyAreaId"
                    v-model="selectedAreas"
                    :key="JSON.stringify(tableValue)"
                >
                    <template v-slot:item.netLetArea="{ item }">
                        <div
                            v-if="
                                selectedAreas.find(
                                    (oneSelectedArea) => oneSelectedArea.propertyAreaId === item.propertyAreaId,
                                )
                            "
                        >
                            <currency-field
                                class="mt-3"
                                dense
                                currency-symbol=""
                                :precision="0"
                                v-model="item.netLetArea"
                            />
                        </div>
                    </template>
                </smart-table>
            </v-col>
        </v-container>
    </Page>
</template>
<script>
import api from '@/api';

const headers = [
    {
        text: 'Area Code',
        align: 'left',
        sortable: true,
        value: 'fullPropertyAreaCode',
    },
    {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'fullPropertyAreaName',
    },
    {
        text: 'Total Area',
        value: 'propertyNetLetArea',
        align: 'right',
    },
    {
        text: 'Leased Area',
        value: 'netLetArea',
        align: 'right',
    },
];

const formSchema = {
    company: {
        component: 'text-field',
        label: 'Company',
        readOnly: true,
        cols: 12,
    },
    customer: {
        component: 'text-field',
        label: 'Customer',
        readOnly: true,
        cols: 12,
    },
    property: {
        component: 'text-field',
        label: 'Property',
        readOnly: true,
        cols: 12,
    },
    reference: {
        component: 'text-field',
        label: 'Reference',
        readOnly: true,
        cols: 12,
    },
};

export default {
    name: 'LeaseArea',
    data() {
        return {
            id: null,
            leaseData: null,
            propertyAreas: null,
            leaseAreas: null,
            tableValue: null,
            headers,
            selectedAreas: [],
            formSchema,
            doneRedirect: null,
        };
    },
    methods: {
        processLoadedData(data) {
            console.log('getLeaseData', data);

            this.leaseData = data.leaseData;

            this.propertyAreas = data.propertyAreas;

            this.leaseAreas = data.leaseAreas;

            this.tableValue = this.propertyAreas.map((onePropertyArea) => {
                const leaseArea = this.leaseAreas.find(
                    (oneLeaseArea) => oneLeaseArea.fullPropertyAreaCode === onePropertyArea.fullPropertyAreaCode,
                );

                return {
                    propertyAreaId: onePropertyArea.id,
                    fullPropertyAreaCode: onePropertyArea.fullPropertyAreaCode,
                    fullPropertyAreaName: onePropertyArea.fullPropertyAreaName,
                    propertyNetLetArea: onePropertyArea.netLetArea,
                    leaseAreaId: leaseArea ? leaseArea.id : null,
                    netLetArea: leaseArea ? leaseArea.netLetArea : onePropertyArea.netLetArea,
                };
            });

            this.selectedAreas = this.tableValue.filter(oneTableValue => this.leaseAreas.find(oneLeaseArea => oneLeaseArea.propertyAreaId === oneTableValue.propertyAreaId));
        },
        async saveFormData() {
            console.log('saveformdata clicked', this.tableValue, this.selectedAreas);

            const result = await api.post(this.$store, `lease/${this.id}/leasearea`, this.selectedAreas);

            if (result.data) {
                this.processLoadedData(result.data);
            }
        },

        async getLeaseData() {
            const result = await api.get(this.$store, `lease/${this.id}/leasearea`);

            if (result.data) {
                this.processLoadedData(result.data);
            }
        },
        async init() {
            this.id = this.$route.params.id;
            this.doneRedirect = `/auth/admin/lease/${this.id}`;
            await this.getLeaseData();
        },
    },
    async created() {
        console.log('Lease Area');
        await this.init();
    },
};
</script>
